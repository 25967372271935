<template>
  <div v-if="userType=='Employee'">
    <van-image src="images/logotop.jpg" fit=""></van-image>
    <van-grid :column-num="2">
      <!-- <van-grid-item icon="plus" text="产品入库" url="WapProductWarehouseIn" />
      <van-grid-item icon="minus" text="产品出库" url="WapProductWarehouseOut" /> -->
      <van-grid-item icon="newspaper-o" text="产品二维码" to="Wap_ProductQRCode" />
      <van-grid-item icon="refund-o" text="设备时效" to="WapDeviceEdit" />
      <van-grid-item icon="lock" text="产品注册(有密钥)" url="WapRegisterDevice" />
      <van-grid-item icon="lock" text="产品注册(无密钥，河北)" to="WapRegisterDevice1" />
      <van-grid-item icon="lock" text="产品注册(无密钥，江阴)" to="WapRegisterDevice2" />

      <van-grid-item icon="newspaper-o" text="下出货单" to="WapProductSelect" />
      <van-grid-item icon="newspaper-o" text="下出货单1" to="Wap_CangKu_Operate" />
    </van-grid>
  </div>
  <van-empty v-else description="无权限"></van-empty>
</template>

<script>
export default {
  data() {
    return {
      userType: "",
    };
  },
  mounted() {
    let that = this;
    that.axios.post("Sys_UserInfo/GetUserType").then(function (response) {
      that.userType = response.data.data;
    });
  },
};
</script>

<style>
</style>